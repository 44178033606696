<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      <b-button class="button is-primary"> Reincarcare </b-button>
    </hero-bar>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Mesaje"
        icon="account-multiple"
      >
        <messages-table :messages="messages"> </messages-table>
      </card-component>
      <hr />
    </section>
  </div>
</template>

<script>
import Messages from "@/services/messages.service";
const _ = require("lodash");
import CardComponent from "@/components/CardComponent";
import TitleBar from "@/components/TitleBar";
import HeroBar from "@/components/HeroBar";
import MessagesTable from "../components/messages/messages-table.vue";

export default {
  name: "Messages",
  components: {
    HeroBar,
    TitleBar,
    CardComponent,
    MessagesTable,
  },
  data() {
    return {
      messages: [],
    };
  },
  created() {
    this.getAllMessages();
  },
  methods: {
    async getAllMessages() {
      const { getMessages } = Messages;
      const response = await getMessages();
      this.messages = response.data;
    },
  },
  computed: {
    titleStack() {
      return ["Mesaje", "Mesaje"];
    },
  },
};
</script>
