import messagesEndpoints from "./endpoints/messages-endpoints";
import apiConfig from "../config/api";
import http from "./http";
import store from "../store/index";

const {
  getMessages,
} = messagesEndpoints;
const { baseURL } = apiConfig;

const Messages = {
  async getMessages() {
    const getMessagesUrl = `${baseURL}${getMessages.GET()}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .get(
        getMessagesUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  }
};

export default Messages;
