<template>
  <div>
    <b-table
      v-if="messages"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="name"
      :data="messages"
      v-slots="props"
    >
      <b-table-column
        custom-key="actions"
        cell-class="is-actions-cell"
        v-slot="props"
      >
        <div class="buttons is-left">
          <router-link
            :to="{
              name: 'volunteer.edit',
              params: { id: props.row.id },
            }"
            class="button is-small is-primary"
          >
            <b-icon icon="file-edit" size="is-small" />
          </router-link>
        </div>
      </b-table-column>
      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column">
          <template
            v-if="column.searchable && !column.numeric"
            #searchable="props"
          >
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Cauta..."
              icon="magnify"
              size="is-small"
            />
          </template>
          <template v-if="column.date" v-slot="props">
            {{ props.row[column.field] | formatDate }}
          </template>
          <template v-else v-slot="props">
            {{ props.row[column.field] }}
          </template>
        </b-table-column>
      </template>
      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Incarcare data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nici o inregistrare&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>
<script>
export default {
  name: "MessagesTable",
  props: {
    messages: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      paginated: true,
      perPage: 10,
      columns: [
        {
          field: "id",
          label: "ID",
          numeric: true,
          visible: false,
        },
        {
          field: "message",
          label: "Mesaj",
          numeric: false,
          sortable: true,
          searchable: true,
          visible: true,
        },
        {
          field: "date",
          label: "Data",
          numeric: false,
          date: true,
          sortable: false,
          searchable: false,
        },
        {
          field: "name",
          label: "Nume",
          numeric: false,
          date: false,
          sortable: true,
          searchable: true,
        },
      ],
    };
  },
};
</script>
